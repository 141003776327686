//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';

export default class extends Controller
{
  static targets = [ 'input' ];
  static classes = [ 'draging', 'denied', 'active' ];
  static values = { disabled: Boolean };


  initialize()
  {
    this.submitStartEvent = this.submitStartEvent.bind( this );
    this.submitEndEvent = this.submitEndEvent.bind( this );

    this.dragOver = this.dragOver.bind( this );
    this.dragLeave = this.dragLeave.bind( this );
    this.dragDrop = this.dragDrop.bind( this );
  }

  connect()
  {
    if( this.isDisabled )
    {
      this.element.disabled = true;
    }
    else
    {
      this.element.addEventListener( 'turbo:submit-start', this.submitStartEvent );
      this.element.addEventListener( 'turbo:submit-end', this.submitEndEvent );
    }

    if( this.hasDropTarget )
    {
      this.dropTarget.addEventListener( 'dragenter', this.dragOver );
      this.dropTarget.addEventListener( 'dragover',  this.dragOver );
      this.dropTarget.addEventListener( 'dragleave', this.dragLeave );
      this.dropTarget.addEventListener( 'drop',      this.dragDrop );
    }

    if( this.hasDeniedTarget )
    {
      this.deniedTarget.addEventListener( 'dragenter', this.dragOver );
      this.deniedTarget.addEventListener( 'dragover',  this.dragOver );
      this.deniedTarget.addEventListener( 'dragleave', this.dragLeave );
    }
  }

  disconnect()
  {
    this.element.removeEventListener( 'turbo:submit-start', this.submitStartEvent );
    this.element.removeEventListener( 'turbo:submit-end', this.submitEndEvent );

    if( this.hasDropTarget )
    {
      this.dropTarget.removeEventListener( 'dragenter', this.dragOver );
      this.dropTarget.removeEventListener( 'dragover',  this.dragOver );
      this.dropTarget.removeEventListener( 'dragleave', this.dragLeave );
      this.dropTarget.removeEventListener( 'drop',      this.dragDrop );
    }

    if( this.hasDeniedTarget )
    {
      this.deniedTarget.addEventListener( 'dragenter', this.dragOver );
      this.deniedTarget.addEventListener( 'dragover',  this.dragOver );
      this.deniedTarget.addEventListener( 'dragleave', this.dragLeave );
    }
  }

  select( e )
  {
    if( this.isDisabled ) return;

    e.preventDefault();
    this.inputTarget.click();
  }

  upload( e )
  {
    if( this.isDisabled ) return;

    e.preventDefault();
    if( this.inputTarget.files && this.inputTarget.files[0] )
    {
      this.inputTarget.form.requestSubmit();
    }
  }

  dragOver( e )
  {
    e.preventDefault();
    e.stopPropagation();

    if( !this.isDisabled && this.hasValidItems( e.dataTransfer.items ))
    {
      e.dataTransfer.dropEffect = "copy";
      this.dragStart();
    }
    else
    {
      e.dataTransfer.dropEffect = "none";
      this.denyStart();
    }
  }

  dragLeave( e )
  {
    e.preventDefault();
    e.stopPropagation();

    if( !this.isDisabled && this.hasValidItems( e.dataTransfer.items ))
    {
      this.dragStop();
    }
    else
    {
      this.denyStop();
    }
  }

  dragDrop( e )
  {
    e.preventDefault();
    e.stopPropagation();


    if( !this.isDisabled && this.hasValidItems( e.dataTransfer.items ))
    {
      this.dragStop();
      this.inputTarget.files = e.dataTransfer.files;
      this.upload( e );
    }
    else
    {
      this.denyStop();
    }
  }


  hasValidItems( items )
  {
    return true;
    // return ( [ ...items ].findIndex( (item) => item.kind === "file" ) >= 0 );
  }


  dragStart()
  {
    if( this.hasDragingClass )
    {
      this.element.classList.add( ...this.dragingClasses );
    }
    else
    {
      this.element.classList.add( 'draging' );
    }
  }

  dragStop()
  {
    if( this.hasDragingClass )
    {
      this.element.classList.remove( ...this.dragingClasses );
    }
    else
    {
      this.element.classList.remove( 'draging' );
    }
  }


  denyStart()
  {
    if( this.hasDeniedClass )
    {
      this.element.classList.add( ...this.deniedClasses );
    }
    else
    {
      this.element.classList.add( 'denied' );
    }
  }

  denyStop()
  {
    if( this.hasDeniedClass )
    {
      this.element.classList.remove( ...this.deniedClasses );
    }
    else
    {
      this.element.classList.remove( 'denied' );
    }
  }


  submitStartEvent( e )
  {
    this.activityStart();
  }

  submitEndEvent( e )
  {
    this.activityStop();
  }


  activityStart()
  {
    if( this.hasActiveClass )
    {
      this.element.classList.add( ...this.activeClasses );
    }
    else
    {
      this.element.classList.add( 'active' );
    }
  }

  activityStop()
  {
    if( this.hasActiveClass )
    {
      this.element.classList.remove( ...this.activeClasses );
    }
    else
    {
      this.element.classList.remove( 'active' );
    }
  }


  get isDisabled()
  {
    return this.disabledValue;
  }

  get hasDropTarget()
  {
    return true;
  }

  get dropTarget()
  {
    return document;
  }

  get dragClass()
  {
    return this.hasDragingClass ? this.dragingClasses :  [ 'draging' ];
  }

  get denyClass()
  {
    return this.hasDeniedClass ? this.deniedClasses :  [ 'denied' ];
  }
}